$(document).ready(function() {
  'use strict';

  $.fn.showControls = function() {
    return this.each(function() {
      var $this = $(this);
      var controls = $this.attr('data-target');

      $(controls).show();
      $this.addClass('selected');
      $this.find('.order-row-indicator').addClass('fa-rotate-90');
    });
  };

  $.fn.hideControls = function() {
    return this.each(function() {
      var $this = $(this);
      var controls = $this.attr('data-target');

      $(controls).hide();
      $this.removeClass('selected');
      $this.find('.order-row-indicator').removeClass('fa-rotate-90');
    });
  };

  $('.order-row').on('click', function(event) {
    var $this = $(this);
    if ($this.hasClass('selected')) {
      $this.hideControls();
    } else {
      // remove selected class from other rows
      $this.parent().find('.order-row.selected').hideControls();
      $this.showControls();
      $('html, body').animate({
        scrollTop: $this.position().top
      }, 200);
    }
  });

  $('.edit-order').on('click', function(event) {
      var $this = $(this);
      $.ajax({
          type: "POST",
          url: '/restful/delegate/easydropshipping/bookedtocart',
          data: { orderId: $this.attr('data-order-id') },
          dataType: 'json',
          success: function(data) {
              if (data.success) {
                  window.location = '/current/cart';
              } else {
                  alert('Cannot edit this order.');
              }
          },
          failed: function(data) {
              showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          }
      });
  });

  $('.delete-order').on('click', function(event) {
      var $this = $(this);
      $.ajax({
          type: "POST",
          url: '/restful/shopping/checkout/payment/cancel',
          data: { orderId: $this.attr('data-order-id') },
          dataType: 'json',
          success: function(data) {
              if (data.success) {
                  window.location.reload();
              } else {
                  alert('Cannot cancel this order.');
              }
          },
          failed: function(data) {
              showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          }
      });
  });

  $('.pay-order').on('click', function(event) {
      var $this = $(this);
      $.ajax({
          type: "POST",
          url: '/restful/shopping/checkout/payment/initialize',
          data: { orderId: $this.attr('data-order-id') },
          dataType: 'json',
          success: function(data) {
              if (data.success) {
                  window.location = '/current/checkout/payment';
              } else {
                  alert('Cannot pay this order.');
              }
          },
          failed: function(data) {
              showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          }
      });
  });

  $('.show-return-form').click(function(event) {
    event.preventDefault();
    var $this = $(this);
    var target = $this.attr('data-target');

    $(target).show();
  });

  $('.new-return-request form').submit(function(event) {
    event.preventDefault();
    $.ajax({
      type: $(this).attr('method'),
      url: $(this).attr('action'),
      data: $(this).serialize(),
      dataType: 'json',
      beforeSend: function(data) {
        $(this).prop('disabled', true);
      },
      success: function(data) {
        if (data.success) {
          //TODO translate
          showMessageBox('success', 'Return Request created successfully');
        } else {
          showMessageBox('danger', data.errors.message);
        }
      },
      failed: function(data) {
        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
      },
      complete: function(data) {
        $(this).prop('disabled', false);
      }
    });
  });

  $('form.cancel-return-request-form').submit(function(event) {
    event.preventDefault();
    $.ajax({
      type: $(this).attr('method'),
      url: $(this).attr('action'),
      data: $(this).serialize(),
      dataType: 'json',
      success: function(data) {
        if (data.success) {
          window.location.href = '/current/profile/returnrequests/1';
        } else {
          showMessageBox('danger', data.errors.message);
        }
      },
      failed: function(data) {
        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
      }
    });
  });

});
