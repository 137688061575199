$(document).ready(function () {

    $('.close-top-banner').on('click', function () {
        $('.top-banner').hide();
        $('.navigation').addClass('top-banner-closed');
        $('#nav-toggle').addClass('top-banner-closed');
    });

    $('#search-trigger').on('click', function (e) {
        e.preventDefault();
        $('#search-modal').addClass('search-open');
    });

    $('.close-search-modal').on('click', function (e) {
        e.preventDefault();
        $('#search-modal').removeClass('search-open');
    });

    //Stickt button

    var btn = $('#button');

    $(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
        btn.addClass('show');
    } else {
        btn.removeClass('show');
    }
    });

    btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
    });

    // sticky menu
    $(window).scroll(function() {
        if ($(this).scrollTop() > 120) {
            $('.navigation').addClass("sticky");
        }
        else {
            $('.navigation').removeClass("sticky");
        }
    });

    // mobile menu
    $('#nav-toggle').click(function() {
        this.classList.toggle("active");
        // If sidebar is visible:
        if ($('body').hasClass('show-nav')) {
            // Hide sidebar
            $('body').removeClass('show-nav');
        } else { // If sidebar is hidden:
            $('body').addClass('show-nav');
            // Display sidebar
        }
    });
});