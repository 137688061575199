$(document).ready(function() {
    $('.cart-update').submit(function(event) {
        event.preventDefault();
        const $this = $(this);
        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    rewixUpdateCart($this);
                    window.location.href = '/current/cart';
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });
    
    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        const $this = $(this);
        let data = {};
        data[$this.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $this.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    const container = $this.closest('.flex-container');
                    rewixUpdateCart(container, true);
                    window.location.href = '/current/cart';
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });
    
    $(".burn-coupon-form").submit(function(event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    $('.carrier-form').change(function() {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            headers: {'Accept': "application/json"},
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });
});