$(document).ready(function() {
    'use strict';

    $('#contact-form').on('submit', function (event) {

      event.preventDefault();

      $("#contact-form").validate({
        rules: {
          firstname: "required",
          email: "required",
          subject: "required",
          message: "required"
        },
        errorPlacement: function(error, element) {
          error.appendTo("");
        }
      });
    
      if ($("#privacy").is(":checked") == true) {
    
        if ($("#contact-form").valid() == true) {
        $.ajax({
              type: $("#contact-form").attr('method'),
              url: $("#contact-form").attr('action'),
              data: $("#contact-form").serialize(),
              beforeSend: function(data) {
              },
              success: function(data) {
                if (data.success) {
                  showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                  $("#contact-form").find(':input').prop('disabled', true);
                } else {
                  showMessageBox('danger', data.errors.message);
                }
              },
              failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
              },
              complete: function(data) {
                grecaptcha.reset();
              }
            })
    
        } else {
          grecaptcha.reset();
        }
    
      } else {
        grecaptcha.reset();
        showMessageBox("danger", "Devi accettare la privacy policy")
      }
    });

    /*Start: Form di contatto landing page */
    $('#contact-form-landing').on('submit', function (event) {

      event.preventDefault();

      $("#contact-form-landing").validate({
        rules: {
          firstname: "required",
          email: "required",
          phone: "required",
          message: "required"
        },
        errorPlacement: function(error, element) {
          error.appendTo("");
        }
      });
    
      if ($("#privacy").is(":checked") == true) {
    
        if ($("#contact-form-landing").valid() == true) {
        $.ajax({
              type: $("#contact-form-landing").attr('method'),
              url: $("#contact-form-landing").attr('action'),
              data: $("#contact-form-landing").serialize(),
              beforeSend: function(data) {
              },
              success: function(data) {
                if (data.success) {
                  showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                  $("#contact-form-landing").find(':input').prop('disabled', true);
                  setTimeout(function() {
                    window.location.href = '/current/cms/thank-you-page'
                }, 500);
                } else {
                  showMessageBox('danger', data.errors.message);
                }
              },
              failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
              },
              complete: function(data) {
                grecaptcha.reset();
              }
            })
    
        } else {
          grecaptcha.reset();
        }
    
      } else {
        grecaptcha.reset();
        showMessageBox("danger", "Devi accettare la privacy policy")
      }
    });
    /*End: Form di contatto landing page */

    $('#contact-form-receipent').on('submit', function (event) {

      event.preventDefault();

      $("#contact-form-receipent").validate({
        rules: {
          firstname: "required",
          email: "required",
          subject: "required",
          message: "required"
        },
        errorPlacement: function(error, element) {
          error.appendTo("");
        }
      });
    
      if ($("#contact-form-receipent").valid() == true) {
        $.ajax({
              type: $("#contact-form-receipent").attr('method'),
              url: $("#contact-form-receipent").attr('action'),
              data: $("#contact-form-receipent").serialize(),
              beforeSend: function(data) {
              },
              success: function(data) {
                if (data.success) {
                  showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                  $("#contact-form-receipent").find(':input').prop('disabled', true);
                } else {
                  showMessageBox('danger', data.errors.message);
                }
              },
              failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
              },
              complete: function(data) {
                grecaptcha.reset();
              }
            })
    
      } else {
        grecaptcha.reset();
      }
    });
  
  });
  
  function contactRecaptchaCallback() {
  
    $("#contact-form").find(':input[type = "submit"]').prop('disabled', false);
    $("#contact-form-receipent").find(':input[type = "submit"]').prop('disabled', false);

  }

  function onloadCallback() {

    $("#contact-form").find(':input[type = "submit"]').prop('disabled', true);
    $("#contact-form-receipent").find(':input[type = "submit"]').prop('disabled', true);

    grecaptcha.render('captcha-div', {
      'sitekey' : '6Leuh0cUAAAAAEG6YqzZ17XdvbPjp10JkIMEqGQ0',
      'callback' : contactRecaptchaCallback,
      'theme' : 'light'
    });
  };
  
  
  