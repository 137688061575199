$(document).ready(function () {

    $('#newsletter-arrow').on('click', function () {
        $('#newsletter-step-2').show();
    });

    $('#subscribe-newsletter').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: function(form) {
            const $form = $(form);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function(data) {
    
                },
                success: function(data) {
                    if (data.success) {
                        $('#newsletter-step-2').hide();
                        $('#mail2').val('');
                        $('#newsletter-success').show();
                    }
                    else {
                        showMessageBox('danger', msg['ERR_INVALIDMAIL']);
                    }
                },
                error: function(data) {
                },
                complete: function(data) {
                    grecaptcha.reset();
                }
            });
        }
    });
});


function subscribeNewsletterCaptcha() {
    $('#subscribe-newsletter button[type=submit]').prop('disabled', false);
}