$(document).ready(function() {
    'use strict';

    function submitAddressEditForm(form, shippingForm=false) {
        var href = form.data('nextstep');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            headers: {'Accept': "application/json"},
            success: function(data) {
                if (data.success && data.data == 'VAT_CHANGED') {
                    href = '//' + location.host + location.pathname + '?warning=msg.order.vat.changed';
                }

                if (shippingForm && window.dataLayer) {
                    dataLayer.push({ ecommerce: null });
                    pushGTMEventWithCallback(500, {
                        event: 'add_shipping_info',
                        ecommerce: window.ecommerce,
                        eventCallback: GTMCallback(function() {
                            window.location.href = href;
                        })
                    });
                }
                else {
                    window.location.href = href;
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }

    $('#dispatch-address-form').validate({
        rules: {
          addressee: 'required',
          country_id: 'required',
          street: 'required',
          number: 'required',
          city: 'required',
          prov: 'required',
          cel_prefix: 'required',
          cel: 'required',
          cap: 'required'
        },
        submitHandler: function(form, event) {
          event.preventDefault();
          submitAddressEditForm($(form), true);
        }
    });
        
    $('#billing-address-form').validate({
        rules: {
          addressee: 'required',
          cfpiva: 'required',
          country_id: 'required',
          street: 'required',
          number: 'required',
          cap: 'required',
          city: 'required',
          prov: 'required'
        },
        submitHandler: function(form, event) {
          event.preventDefault();
          if(!$('#clausola1').is(':checked')) {
            showMessageBox('danger', msg['ERR_PRIVACY']);
          } else {
            if ($( "#country" ).find('option:selected').data('eu') == 'EU' || $( "#country" ).find('option:selected').data('eu') == 'IT') {
                if (($('#pec').val() == '')&&($('#sdi').val() == '')) {
                    if ($('#pec').val() == '') {
                        $('#pec').addClass('is-invalid-input')
                    }
                    showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
                } else {
                    submitAddressEditForm($(form));
                }
            }else {
                submitAddressEditForm($(form));
            }
          }
        }
    });
    
    var currentCountry = $( "#country" ).find('option:selected').data('eu');

    if (currentCountry == 'EU' || currentCountry == 'IT' ) {
        $('#pec-div').removeClass('hide');
        $('#sdi-div').removeClass('hide');
    } else {
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
    }

    $( "#country" ).change(function() {
        var selected = $(this).find('option:selected');
        var region = selected.data('eu'); 

        if (region == 'EU' || region == 'IT' ) {
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#pec').val('');
            $('#sdi').val('');
        } else {
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#pec').val('');
            $('#sdi').val('');
        }

    });

    $('[name*="country"]').on('change', updateProvinces);
    updateProvinces();
    
    function updateProvinces() {
        $('[name*="prov"]').each(function() {
            const $this = $(this);
            const country = $this.closest('form').find('[name*="country"]');
    
            if (country.length > 0) {
                loadProvinces(country.val()).then(function(provinces) {
                    if (provinces.length > 0) {
                        const id = $this.attr('id');
                        const name = $this.attr('name');
                        const type = $this.attr('type');
                        const value = $this.val();
                        const placeholder = $this.attr('placeholder');
                        let options = [];
                        let hasSelectedValue = false;
    
                        for (let i = 0; i < provinces.length; i++) {
                            const province = provinces[i];
                            const selected = (province.code == value) ? 'selected' : '';
                            options.push(`<option value="${province.code}" ${selected}>${province.name} (${province.code})</option>`);
                            hasSelectedValue = hasSelectedValue || (province.code == value);
                        }
                        if (!hasSelectedValue) {
                            options[0].replace('  ', ' selected');
                        }
                        $this.replaceWith(`
                            <select id="${id}" name="${name}" data-type="${type}" data-placeholder="${placeholder}" data-value="${value}" required aria-required="true">
                                ${options.join('\n')}
                            </select>
                        `);
                    }
                    else if ($this.is('select')) {
                        const id = $this.attr('id');
                        const name = $this.attr('name');
                        const type = $this.data('type');
                        const value = $this.data('value');
                        const placeholder = $this.data('placeholder');
                        $this.replaceWith(`<input id="${id}" type="${type}" name="${name}" value="${value}" placeholder="${placeholder}" required aria-required="true"/>`);
                    }
                });
            }
        });
    }

    $('#cod-btn').on('click', function(event) {
        const btn = this;

        if (window.dataLayer) {
            event.preventDefault();
            event.stopPropagation();

            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: 'add_payment_info',
                ecommerce: window.ecommerce('COD'),
                eventCallback: GTMCallback(function() {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });

    $('#booking-btn').on('click', function(event) {
        const btn = this;

        if (window.dataLayer) {
            event.preventDefault();
            event.stopPropagation();

            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: 'add_payment_info',
                ecommerce: window.ecommerce('Booking'),
                eventCallback: GTMCallback(function() {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });
});