$(document).ready(function() {

    $('.tab').on('click', function () {
        var tab = $(this).data('tab');
        $('.tab').removeClass('active');
        $('.tab-container').removeClass('active');

        $(this).addClass('active');
        $("#" + tab).addClass('active');
    });


    $('.shop-product').on('submit', '.addtocart-form', function(event) {
        event.preventDefault();
        var form = $(this);
        var selected = '#' + $('.product-sizes input:checked').attr('id');

        if (selected === '#no-size-selected') {
            showMessageBox('danger', msg['ERR_NOSIZESELECTED']);
        }
        else {
            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: form.serialize(),
                headers: {'Accept': 'application/json'},
                beforeSend: function(data) {
                    $('.btn-loader').show();
                },
                success: function(data) {
                    if (!data.success) {
                        if (data.code === 'MIXED_INTANGIBLE') {
                            showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                        }
                        else if (data.code === 'MIXED_CART') {
                            showMessageBox('danger', msg['MSG_MIXED_CART']);
                        }
                        else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                            showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                        }
                        else if (data.code === 'LOGIN_REQUIRED') {
                            location.href = '/' + $('html').attr('lang') + '/login';
                        }
                        else {
                            showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                        }
                    }
                    else {
                        updateCartCount(window.location.href, function() {
                            $('#offCanvasCart').foundation('open', event, form);
                        });

                        if (window.location.href.indexOf('catalog') > 0) {
                            updateAddToCart(window.location.href, function() {
                                $(selected).click();
                            });
                        }
                    }
                },
                error: function(xhr, textStatus, errorThrown) {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                },
                complete: function(data) {
                    $('.btn-loader').hide();
                }
            });
        }


    });
});

function updateAddToCart(url, callback) {
    $('.addtocart-form-container').load(url + ' .addtocart-form', function() {
        if (typeof callback !== 'undefined') {
            callback();
        }
    });
}

function updateCartCount(url, callback) {
    $('#offCanvasCart').load(url + ' #offCanvasCartContainer', function() {
        if (typeof callback !== 'undefined') {
            callback();
        }
    });
}

function loadQtyInputs() {
    $('.i-number').each(function() {
        const $this = $(this);

        if (!$this.data('initialized')) {
            const max = $this.data('max') >> 0;
            const min = $this.data('min') >> 0;
            const initValue = $this.val();
            let restoreColor = () => {};
    
            checkValue(initValue);
            if (initValue > max || initValue < min) {
                $this.css('color', 'red');
                restoreColor = () => $this.css('color', false);
            }
    
            $('<a class="i-number-btn i-number-minus" role="button" tabindex="-1"><img src="/skins/kidsdistribution2018/images/qty-minus.svg"/></a>')
                .insertBefore($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value - 1);
                });
            
            $('<a class="i-number-btn i-number-plus" role="button" tabindex="-1"><img src="/skins/kidsdistribution2018/images/qty-plus.svg"/></a>')
                .insertAfter($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value + 1);
                });
    
            $this.on('keydown input', function(event) {
                const value = getCurrentValue($this);
                const key = (event.key || '').toUpperCase();

                if (key == 'ENTER') {
                    event.preventDefault();
                    $this.closest('form').trigger('submit');
                }
                else if (key == 'ARROWUP') {
                    checkValue(value + 1);
                }
                else if (key == 'ARROWDOWN') {
                    checkValue(value - 1);
                }
                else {
                    checkValue(value);
                }
            });

            $this.data('initialized', true);
    
            function getCurrentValue($this) {
                return $this.val().toString().replace(/[^0-9]/g, '') >> 0;
            }
    
            function checkValue(value) {
                if (value > max) {
                    $this.val(max);
                }
                else if (value < min) {
                    $this.val(min);
                }
                else {
                    $this.val(value);
                }
                restoreColor();
                checkSubmitEnabled();
            }

            function checkSubmitEnabled() {
                const $form = $this.closest('form');
                const totalQuantity = $form.find('.i-number').toArray()
                    .map(input => $(input).val() >> 0)
                    .reduce((a,b) => a + b);

                if (totalQuantity > 0) {
                    $form.find('[type="submit"]').prop('disabled', false);
                }
                else {
                    $form.find('[type="submit"]').prop('disabled', true);
                }
            } 
        }
    });
}