$(document).ready(function() {

    if (getUrlParam('errorCode') == '263') {
        $('#login-form').addClass('hide');
        $('#login-form-otp').removeClass('hide');
    }

    function submitUserUpdateForm(form) {
        const $form = $(form);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    }

    $('#user-update-data-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: function(form) {
            if (($('#pec').val() == '')&&($('#sdi').val() == '')) {
                if ($('#pec').val() == '') {
                    $('#pec').addClass('is-invalid-input')
                }
                showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
            } else {
                submitUserUpdateForm(form);
            }
        }
    });

    $('#user-update-dispatch-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-invoice-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#password-change-form').submit(function (event) {
        event.preventDefault();
        if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test($("#password").val())) {
            showMessageBox('danger', msg['passwordnotcomplex']);
        } else if($("#password").val() !== $("#password_confirm").val()) {
            showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
        } else {
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                beforeSend: function (data) {
                    $(this).find(':input').prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_PASSWORD_OK']);
                    } else {
                        showMessageBox('danger', msg[data.errors.message]);
                    }
                },
                complete: function (data) {
                    $(this).find(':input').prop('disabled', false);
                }
            });
        }
    });

    // profile consent ajax
    $('#profile-consent-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents':[]
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function(data) {

            },
            success: function(data) {

            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            }
        });
    });
});