$(document).ready(function () {

    // show hide password recovery
    $('.js-toggle-show').on('click', function (event) {
        event.preventDefault();
        var $this = $(this);

        $($this.attr('data-show')).slideDown();
        $($this.attr('data-hide')).slideUp();
    });



    $('#password-recover-form').submit(function(event) {
        event.preventDefault();

        var $form = $(this);

        var email = $form.find('#recoveremail').val();
        var password = $form.find('#recoverpassword').val();

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data : {
                email : email,
                password : password
            },
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    });



    $('#subscribe-form').submit(function(event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    if (window.dataLayer) {
                        pushGTMEventWithCallback(500, {
                            event: 'sign_up',
                            eventCallback: GTMCallback(function() {
                                window.location = $form.data('redirectsuccess');
                            })
                        })
                    }
                    else {
                        window.location = $form.data('redirectsuccess');
                    }
                }
                else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    });

});